.main-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.sub-menu {
    position: absolute !important;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.sub-menu-list {
    background-color: #262e3e;
    color: #e8ebf1;
    border-radius: 12px;
}

.user-message {
    margin: 10px 0;
    min-height: 65px;
}

.light-grey,
.text {
    color: #E2E3E4 !important;
    cursor: default;
}

.username {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-icon {
    margin-left: 5px;
}

.log-list {
    margin: 10px 0;
}

.input-field {
    border-color: lightblue !important;
    color: lightblue !important;
}