body {
  margin: 0;
  background-color: #151a22;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  
  /*   overscroll-behavior: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-button {
  margin: 8px 0 !important;
  border-radius: 12px !important;
  border-width: 2px !important;
  border-color: lightblue !important;
  color: lightblue !important;
  width: 260px;
}
