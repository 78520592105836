html, body, #root, #root>div {
  font-family: 'Ubuntu', sans-serif !important;
  height: 100%
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40px;
  --margin-left: 20px;
  z-index: 99;
  position: relative;
}

.App-version {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 22px;
}

.App-name {
  --margin-top: 10px;
  text-transform: uppercase;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E2E3E4;
  padding: 40px 10px 10px;
}

.App-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 10px 10px;
  max-height: 600px;
}

.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media (max-height: 670px) {
  .App-header {
    flex-direction: row;
    padding-top: 20px;
    height: 50px;
  }

  .App-logo {
    height: 45px; 
    transform: scale(-1, 1);
    margin-left: unset;
    height: inherit;
  }

  .App-name {
    margin-left: 5px;
    margin-top: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App-logo:hover {
  animation: fadeout 3s linear normal 1;
}

.App-logo:hover + .App-version {
  animation: fadein 3s linear normal 1;
}
